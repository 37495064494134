import axios from '@/plugins/axios';

export default {
  getOrganisationRoles(config = {}) {
    return axios.get('/v1/organisation/roles', config);
  },
  createOrganisationRole(data, config = {}) {
    return axios.post('/v1/organisation/roles', data, config);
  },
  getOrganisationRole(organisationRoleId, config = {}) {
    return axios.get(`/v1/organisation/roles/${organisationRoleId}`, config);
  },
  updateOrganisationRole(organisationRoleId, data, config = {}) {
    return axios.put(`/v1/organisation/roles/${organisationRoleId}`, data, config);
  },
  deleteOrganisationRole(organisationRoleId, config = {}) {
    return axios.delete(`/v1/organisation/roles/${organisationRoleId}`, config);
  },
};
