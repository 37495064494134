<template>
  <div>
    <page-heading :heading="$t('roles')">
      <template v-slot:actions>
        <app-button :label="$t('create')" @click="openCreateModal()"></app-button>
      </template>
    </page-heading>

    <form-open class="grid lg:grid-cols-4 grid-cols-2 gap-4 mb-10" @submit="filter">
      <form-text
        :label="$t('search')"
        :required="false"
        :show-validation-label="false"
        icon="search"
        input-id="search"
        v-model="query.filter.name"
      />
      <app-button class="hidden"/>
    </form-open>

    <loading-wrapper>
      <app-table :items="items">
        <template
          v-slot:headings
        >
          <app-th-sortable :label="$t('model.organisation_role.name')" name="name" :sort="query.sort" @updateSort="updateSort"/>
          <app-th :heading="$t('model.organisation_role.permissions')"/>
          <app-th/>
        </template>

        <template
          v-slot:row="{ item }"
        >
          <app-td>
            <a href="#" @click.prevent="openEditModal(item.id)" class="link">{{ item.name }}</a>
          </app-td>
          <app-td class="space-x-2">
            <badge theme="highlight" v-for="permission in item.permissions" :key="`item-${item.id}-badge-permission-${permission.id}`"
              :label="$t(`permissions.${permission.name}.label`)"
            />
          </app-td>
          <app-td>
            <app-options>
              <app-option-edit @click="openEditModal(item.id)"/>
              <app-option-delete @click="openDeleteModal(item.id)"/>
            </app-options>
          </app-td>
        </template>
      </app-table>
      <pagination
        :meta="meta"
        :page="query.page"
        v-if="meta"
        @pageUpdated="updatePage"
      />
    </loading-wrapper>

    <slideover :active="modals.createEdit" @close="closeModal('createEdit')" @submit="createOrUpdateModel"
      :title="$t(modelId ? 'edit': 'create')"
    >
      <debug>{{ model }}</debug>
      <form-text input-id="name" :form-id="formId" form-error-id="name" :label="$t('model.organisation_role.name')" v-model="model.name"/>
      <form-select
        :allow-empty="true"
        :form-id="formId"
        :label="$t('model.organisation_role.permissions')"
        :multiple="true"
        :options="data.permissions"
        form-error-id="permissions"
        input-id="permissions"
        v-model="model.permissions"
      />

      <template v-slot:buttons>
        <app-button class="ml-2"
          :label="$t(modelId ? 'update' : 'create')"
          :disabled="loading"
          :loading="loading"
        />
      </template>
    </slideover>

    <modal-delete :active="modals.delete" @close="closeModal('delete')" @delete="deleteModel"
      :title="$t('delete')"
      :text="$t('delete_thing_text', { thing: model.name })"
    />
  </div>
</template>

<script>
import ApiOrganisationRoleService from '@/services/api/organisation_role';
import ApiPermissionService from '@/services/api/permission';
import Common from '@/mixins/common';

export default {
  data() {
    return {
      data: {
        permissions: [],
      },
      formId: 'createEdit',
      items: [],
      meta: {},
      modals: {
        createEdit: false,
        delete: false,
      },
      model: this.emptyModel(),
      modelId: false,
      query: {
        filter: {},
        page: 1,
        sort: [
          'name',
        ],
      },
    };
  },
  methods: {
    createOrUpdateModel() {
      if (this.modelId) {
        ApiOrganisationRoleService
          .updateOrganisationRole(this.modelId, this.model, {
            formId: this.formId,
            showMessage: true,
          })
          .then(() => {
            this.getListings();
            this.closeModals();
          })
          .catch(() => {});
      } else {
        ApiOrganisationRoleService
          .createOrganisationRole(this.model, {
            formId: this.formId,
            showMessage: true,
          })
          .then((response) => {
            const { data } = response.data;

            this.modelId = data.id;
            this.getListings();
            this.closeModals();
          })
          .catch(() => {});
      }
    },
    deleteModel() {
      ApiOrganisationRoleService.deleteOrganisationRole(this.modelId).then(() => {
        this.getListings();
      }).catch(() => {});

      this.closeModals();
    },
    emptyModel() {
      return {
        permissions: [],
      };
    },
    filter() {
      this.query.page = 1;
      this.getListings();
    },
    getListings() {
      ApiOrganisationRoleService.getOrganisationRoles({
        params: {
          ...this.query,
          include: [
            'permissions',
          ],
        },
      }).then((response) => {
        const { data, meta } = response.data;

        this.items = data;
        this.meta = meta;
      }).catch(() => {});
    },
    getAndSetModel(modelId) {
      return ApiOrganisationRoleService.getOrganisationRole(modelId).then((response) => {
        const { data } = response.data;

        this.modelId = data.id;
        this.model = this.resourceToModel(data);
      }).catch(() => {});
    },
    openCreateModal() {
      this.modelId = false;
      this.model = this.emptyModel();

      this.openModal('createEdit');
    },
    openDeleteModal(modelId) {
      this.getAndSetModel(modelId).then(() => {
        this.openModal('delete');
      });
    },
    openEditModal(modelId) {
      this.getAndSetModel(modelId).then(() => {
        this.openModal('createEdit');
      });
    },
    resourceToModel(data) {
      return {
        name: data.name,
        permissions: data.permissions.map(({ id }) => id),
      };
    },
    routeQueryUpdated() {
      this.getListings();
    },
  },
  mixins: [
    Common,
  ],
  mounted() {
    ApiPermissionService.getOrganisationPermissions({
      params: {
        per_page: -1,
        sort: 'name',
      },
    }).then((response) => {
      const { data } = response.data;

      this.data.permissions = this.toSelectOptions(data, 'name', 'id', (value, label) => `permissions.${label}.label`);
    }).catch(() => {});

    this.getListings();
  },
};
</script>
